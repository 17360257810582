import {Container, Text, Box, Table, Thead, Tr, Th, Tbody, Td, TableContainer} from "@chakra-ui/react";
import React from "react";
import {usePokerRoomStore} from "../http/PokerRoomStore";
import {SessionInfo} from "../types/Message";

export const RightBar: React.FC<SessionInfo> = (sessionInfo: SessionInfo) => {

    const [roomName, users, round, votingSystem] = usePokerRoomStore((state) => [state.name, state.users, state.round, state.votingSystem]);

    return (
        <Container
            backgroundColor="lightblue"
            maxW="sm"
            m="0"
            p="5"
        >
            <Box mb="10">
                <Text>The room is: {roomName}</Text>
                <Text>The sequence is:</Text>
                <Text>{votingSystem}</Text>
                <Text>You are: {users.get(sessionInfo.userId)}</Text>
            </Box>

            <TableContainer>
                <Table>
                    <Thead>
                        <Tr>
                            <Th>Player</Th>
                            <Th>Vote</Th>
                        </Tr>
                    </Thead>
                    <Tbody>
                        {Array.from(round).map(([k, v]) => {
                            return (
                                <Tr key={`tr-${k}-${v}`}>
                                    <Td>{users.get(k)}</Td>
                                    <Td>{v}</Td>
                                </Tr>
                            );
                        })}
                    </Tbody>
                </Table>
            </TableContainer>
        </Container>)
};