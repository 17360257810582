
const determineBaseUrl = () => {
    if (process.env.NODE_ENV === "production") {
        return process.env.REACT_APP_PROD_PPOKER_REST_URL;
    }
    return process.env.REACT_APP_DEV_PPOKER_REST_URL ?? "http://localhost:8080";
}

const HTTP_BASEURL = `${determineBaseUrl()}/poker-room`;

type HttpMethod = "GET" | "PUT" | "POST" | "DELETE";

export type HttpResponse = {code: number, ok: boolean, body?: unknown};

export const post = (endpoint: string, body?: object) => {
    return call(endpoint, "POST", body ? JSON.stringify(body) : null);
}

const call = async (endpoint: string, method: HttpMethod, body?: string): Promise<HttpResponse> => {
    const response = await fetch(`${HTTP_BASEURL}/${endpoint}`, {
        method: method,
        body: body ?? null,
        headers: body == null ? {} : {'Content-Type': 'application/json'},
    })
    if (response.ok) {
        const ret = await response.json()
        return {
            code: response.status,
            ok: true,
            body: ret
        };
    } else {
        return {
            code: response.status,
            ok: false
        }
    }
}