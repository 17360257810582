import React from 'react';
import ReactDOM from 'react-dom/client';

// 1. import `ChakraProvider` component
import { ChakraProvider } from '@chakra-ui/react'
import { MainView } from 'components/MainView'
import { BrowserStateLoader } from 'components/BrowserStateLoader';

function App() {
  
  return (
    <React.StrictMode>
      <ChakraProvider>
        <BrowserStateLoader>
          <MainView/>
        </BrowserStateLoader>
      </ChakraProvider>
    </React.StrictMode>
  )
}

const domNode = document.getElementById("root");
if (domNode == null) {
  console.log("fragrant error");
} else {
  const root = ReactDOM.createRoot(domNode);
  root.render(<App/>);
}
