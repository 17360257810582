import { newPpokerError, PpokerError } from "types/Util";
import { create } from "zustand";
import { HttpResponse } from "./Http"

type Store = {
    error: PpokerError | null;
}

type Actions = {
    setError: (err: HttpResponse) => void;
}

export const useHttpStore = create<Store & Actions>((set, get) => ({
    error: null,
    setError: (err) => {
        set({error: newPpokerError(err.code)});
    }
}))