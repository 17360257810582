export type Consumer<T> = (arg: T) => void;

export type ListConsumer<T> = (list: T[], handler: Consumer<T>) => void;

/** An approximate type signature for the `set` function that's passed to `create`. */
export type ZustandSetter<T> = (partial: (T) | Partial<T> | ((state: T) => (T)), replace?: boolean) => void;
/** Type signature for the `get` function that's passed to `create`. */
export type ZustandGetter<T> = () => T; 

export type PpokerErrorType = "NOT_FOUND" | "TIMEOUT" | "GOING_AWAY" 
                            | "SERVER_ERROR" | "UNKNOWN_ERROR" | "CLOSED_ABNORMALLY"
export type PpokerError = {
    type: PpokerErrorType
    reason: string
}

export const newPpokerError = (code?: number, reason?: string) => {

    // >= 1000 == WS error, <= 1000 == HTTP error

    let type: PpokerErrorType;
    switch(code) {
        case 404:  type = "NOT_FOUND"; break;
        case 500:  type = "SERVER_ERROR"; break;
        case 1000: type = "TIMEOUT"; break;
        case 1001: type = "GOING_AWAY"; break;
        case 1002: type = "SERVER_ERROR"; break;
        case 1006: type = "CLOSED_ABNORMALLY"; break; // Thrown by Cloud Run on socket timeout
        default:   type = "UNKNOWN_ERROR"; break;
    }
    
    return {
        type: type,
        reason: reason ?? ""
    }
}