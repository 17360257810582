import { create } from "zustand"
import {PokerRoom} from "../types/Poker";

type Actions = {
    setupStore: (info: PokerRoom) => void
    setId: (roomId: string) => void
    addUser: (userId: string, username: string) => void
    removeUser: (userId: string) => void
    addVote: (userId: string, vote: string) => void
    removeVote: (userId: string) => void
    clearVotes: () => void
}

export const usePokerRoomStore = create<PokerRoom & Actions>((set, get) => ({

    id: null,
    name: null,
    users: new Map<string, string>(),
    round: new Map<string, string>(),
    votingSystem: null,

    setupStore(data: PokerRoom){
        set(data)
    },

    setId(roomId: string){
        set({id: roomId})
    },

    addUser(userId: string, username: string){
        const updatedMap = new Map(get().users);
        updatedMap.set(userId, username);
        set({users: updatedMap});
    },

    removeUser(userId: string){
        const updatedMap = new Map(get().users);
        updatedMap.delete(userId)
        set({users: updatedMap})
    },

    addVote(userId: string, vote: string){
        const updatedMap = new Map(get().round);
        updatedMap.set(userId, vote);
        set({round: updatedMap})
    },

    removeVote(userId: string){
        const updatedMap = new Map(get().round);
        updatedMap.delete(userId);
        set({round: updatedMap});
    },

    clearVotes(){
        set({round: new Map()})
    }
}));