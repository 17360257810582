
const fibonacciShort = [ "0", "1", "2", "3", "5", "8", "13", "21", "?" ] as const;
export const DefaultPokerValues = fibonacciShort;

export type PokerRoom = {
    id: string;
    name: string;
    users: Map<string, string>;
    round: Map<string, string>;
    votingSystem: string;
}
