import { usePokerRoomStore } from "http/PokerRoomStore";
import { useSocketStore } from "http/SocketStore"
import { useEffect } from "react"
import { isSessionInfo } from "types/Message"
import { storageManagerFor } from "util/StorageManager";

export const BrowserStateLoader = (props: React.PropsWithChildren<{}>) => {

    const PPOKER_STORAGE_KEY = "ppoker";

    const [sessionInfo, tryConnect] = useSocketStore((state) => [state.sessionInfo, state.openConnectionWithSessionInfo]); 
    const [roomId, setRoomId] = usePokerRoomStore((state) => [state.id, state.setId]);

    const storageManager = storageManagerFor(sessionStorage, isSessionInfo);

    // Set the roomId from the pathname.
    useEffect(() => {
        if (roomId == null) {
            let pathSegments = window.location.pathname.split("/");
            if (pathSegments.length > 1 && pathSegments[1].length !== 0) {
                setRoomId(pathSegments[1]);
            }
        }
    }, [roomId])

    // Check if there's pre-existing sessionInfo for this room.
    useEffect(() => {

        const item = storageManager.get(PPOKER_STORAGE_KEY);
        if (item != null && roomId != null) {
            if (roomId === item.roomId) {
                tryConnect(item.roomId, item.userId);
            } else {
                storageManager.del(PPOKER_STORAGE_KEY)
            }
        }
    }, [roomId]);

    useEffect(() => {

        if (sessionInfo.roomId !== "" && sessionInfo.userId !== "") {
            storageManager.set(PPOKER_STORAGE_KEY, sessionInfo);
        }
    }, [sessionInfo]);

    return (
        <>
            {props.children}
        </>
    )

}