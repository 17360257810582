import { Message, SessionInfo, SessionInfoPlaceholder } from "types/Message"
import { newPpokerError, PpokerError, ZustandGetter, ZustandSetter } from "types/Util"
import { create } from "zustand"

type Store = {
    /** Should not be accessed directly. Use actions instead. */
    _socket: WebSocket | null

    /** Contains information required by the websocket to create the connection. */
    sessionInfo: SessionInfo

    /** A queue of messages received from the socket. */
    messageQueue: Message<unknown>[]
    
    error: PpokerError
}

type Actions = {
    openConnectionWithSessionInfo: (roomId: string, userId: string) => WebSocket
    /** 
     * Opens a WebSocket connection to the `ppoker` server if one isn't already open,
     * and returns it.
     */
    openConnectionIfRequired: () => WebSocket
    /** Sends a {@linkcode Message} to the socket, opening it if required. */
    send: (message: Message<any>) => void
    /** 
     * Sets the session information to be used to establish the connection.
     * Must be called before attempting to use or open the connection.
     */
    setSessionInfo: (info: SessionInfo) => void
}

/** Private function that actually opens the connection and binds handlers to it. */
const _createConn = (set: ZustandSetter<Store & Actions>, get: ZustandGetter<Store & Actions>) => {

    const {roomId, userId} = get().sessionInfo;
    let baseUrl: string;
    if (process.env.NODE_ENV === "production") {
        baseUrl = process.env.REACT_APP_PROD_PPOKER_WEBSOCKET_URL;
    } else {
        baseUrl = process.env.REACT_APP_DEV_PPOKER_WEBSOCKET_URL ?? "ws://localhost:8080";
    }

    const connStr = `${baseUrl}/poker/${encodeURIComponent(roomId)}/${encodeURIComponent(userId)}`;

    const socket = new WebSocket(connStr);

    socket.onopen = (ev) => {

        console.log(`Socket opened at ${socket.url}`);
    };
    socket.onmessage = (ev) => {

        /** Parse and enqueue the messsage. */
        const str = ev.data as string;
        const parsed = JSON.parse(str) as Message<unknown>;
        set({ messageQueue: [...get().messageQueue, parsed] })
    };
    socket.onclose = (ev) => {

        const error = newPpokerError(ev.code, ev.reason);
        
        // Non-fatal, this occurs when navigating away from/refreshing page
        if (error.type === "GOING_AWAY") {
            return;
        }

        // Killed by Cloud Run; try to reconnect
        if (error.type === "CLOSED_ABNORMALLY") {
            console.log("Socket closed by remote, attempting to reconnect");
            get().openConnectionIfRequired();
            return;
        }
        
        set({error: error});
    }

    return socket;
}

export const useSocketStore = create<Store & Actions>((set, get) => ({

    _socket: null,
    sessionInfo: SessionInfoPlaceholder,
    error: null,
    messageQueue: [],

    setSessionInfo: (info: SessionInfo) => {
        set({sessionInfo: info});
    },
    openConnectionWithSessionInfo: (roomId: string, userId: string) => {
        set({sessionInfo: {roomId: roomId, userId: userId}})
        return get().openConnectionIfRequired();
    },
    isReady: () => {
        const socket = get()._socket;
        return socket !== null && socket.readyState !== WebSocket.OPEN;
    },
    openConnectionIfRequired: () => {

        let socket: WebSocket | null = get()._socket;
        if (socket === null || 
            socket.readyState === WebSocket.CLOSING || 
            socket.readyState === WebSocket.CLOSED) {

            socket = _createConn(set, get);
            set({_socket: socket, error: null});
        }
        return socket;
    },
    send: (message: Message<unknown>) => {
        const socket = get().openConnectionIfRequired();
        socket.send(JSON.stringify(message));
    }
}));