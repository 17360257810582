import { Heading, Table, TableContainer, Tbody, Td, Th, Thead, Tr, VStack } from "@chakra-ui/react"
import { VotingFinalisedMessage } from "types/Message";
import {usePokerRoomStore} from "../http/PokerRoomStore";

export const ResultsTable = (props: VotingFinalisedMessage) => {

    const users = usePokerRoomStore((state) => state.users);

    return (
        <VStack>
            <Heading>Average: {props.average}</Heading>
            <TableContainer>
                <Table>
                    <Thead>
                        <Tr>
                            <Th>Player</Th>
                            <Th>Vote</Th>
                        </Tr>
                    </Thead>
                    <Tbody>
                        {Object.entries(props.votes).map(([k, v]) => {
                            return (
                                <Tr key={`tr-${users.get(k)}-${v}`}>
                                    <Td>{users.get(k)}</Td>
                                    <Td>{v}</Td>
                                </Tr>
                            );
                        })}
                    </Tbody>
                </Table>
            </TableContainer>
        </VStack>
    )
}