import React from "react";
import {useForm} from 'react-hook-form'
import {Button, Container, FormControl, FormLabel, Input} from "@chakra-ui/react";
import {usePokerRoomStore} from "../http/PokerRoomStore";
import {useSocketStore} from "../http/SocketStore";
import { useHttpStore } from "http/HttpStore";
import { post } from "http/Http";
export const NewPlayer = () => {

    const roomId = usePokerRoomStore((state) => state.id);
    const openConnection = useSocketStore((state) => state.openConnectionWithSessionInfo);
    const setHttpError = useHttpStore((state) => state.setError);

    const {
        handleSubmit,
        register,
        formState: { isSubmitting },
    } = useForm()
    async function onSubmit(values: {username: string}) {
        const response = await post(`${roomId}/player/${values.username}`);
        if (!response.ok) {
            setHttpError(response);
            return;
        }

        const userId = response.body as string;
        openConnection(roomId, userId);
    }

    return (
        <Container>
            <form onSubmit={handleSubmit(onSubmit)}>
                <FormControl>
                    <FormLabel>player name</FormLabel>
                    <Input {...register("username")}/>
                </FormControl>
                <Button
                    mt={4}
                    colorScheme='teal'
                    width={"100%"}
                    isLoading={isSubmitting}
                    type='submit'>
                    Join game
                </Button>
            </form>
        </Container>)
}