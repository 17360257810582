import {Button, Container, Flex, Text, useToast} from "@chakra-ui/react"
import { useSocketStore } from "http/SocketStore"
import {usePokerRoomStore} from "http/PokerRoomStore";
import { useEffect, useState } from "react"
import {
    InstructionMessage,
    newInstructionMessage,
    newUserVotedMessage,
    PokerContextMessage,
    UserStateChangeMessage,
    UserUpdateMessage,
    UserVotedMessage,
    VotingFinalisedMessage, WelcomeUserMessage
} from "types/Message"
import { DefaultPokerValues } from "types/Poker"
import { ResultsTable } from "./ResultsTable"


export const PokerView = () => {

    const toast = useToast();

    const notify = (title: string, message: string) => {
        toast({
            title: title,
            description: message,
            position: "bottom-right"
        })
    }

    const PokerCard = (props: {value: string, onClick: (index: string) => void}) => {

        const onClick = (e: React.MouseEvent<HTMLButtonElement>) => {
            
            props.onClick(props.value);
        }

        return (
            <Button size="lg" 
                    minW={16} 
                    height={32} 
                    onClick={onClick} 
                    colorScheme={selectedCard === props.value ? "blue" : "gray"}
                    isDisabled={lastRoundResults !== null}
            >
                <Text size="3xl">
                    {props.value}
                </Text>
            </Button>
        )
    }

    const [cardValues, _setCardValues] = useState<readonly string[]>(DefaultPokerValues);
    const [selectedCard, setSelectedCard] = useState<string | null>(null);
    const [lastRoundResults, setLastRoundResults] = useState<VotingFinalisedMessage | null>(null);
    const [session, messageQueue, sendMessage] = useSocketStore((state) => [state.sessionInfo, state.messageQueue, state.send]);
    const [
        roomId,
        users,
        addUser,
        removeUser,
        addVote,
        removeVote,
        setupPokerStore,
        clearVotes
    ] = usePokerRoomStore((state) => [state.id, state.users, state.addUser, state.removeUser, state.addVote, state.removeVote, state.setupStore, state.clearVotes]);

    //
    //
    // User action handling
    //
    //
    const onSelectCard = (index: string) => {
        if (index === selectedCard) {
            setSelectedCard(null);
            removeVote(session.userId)
            sendMessage(newUserVotedMessage(session.userId, "-1"));
            return;
        }
        setSelectedCard(index);
        addVote(session.userId, index);
        sendMessage(newUserVotedMessage(session.userId, index));
    }

    const onTallyVotes = () => {
        sendMessage(newInstructionMessage(session.userId, "TALLY_VOTES"));
    }

    const onClearVotes = () => {
        setSelectedCard(null);
        setLastRoundResults(null);
        clearVotes();
        sendMessage(newInstructionMessage(session.userId, "CLEAR_VOTES"));
    }

    const handleUserVotedMessage = (msg: UserVotedMessage) => {
        if (msg.vote === "-1") {
            addVote(msg.userId, "x")
            notify("Vote received", `${users.get(msg.userId)} voted`);
        } else {
            notify("Vote received", `${users.get(msg.userId)} voted ${msg.vote}`);
            addVote(msg.userId, msg.vote)
        }
    }

    const handleUserStateChangeMessage = (msg: UserStateChangeMessage) => {
        if (msg.state === "DISCONNECTED") {
            removeUser(msg.userId);
            notify("User disconnected", `${users.get(msg.userId)} left the session`);
        }
    }

    const handleUserUpdateMessage = (msg: UserUpdateMessage) => {
        if (msg.state === "JOINED") {
            addUser(msg.userId, msg.username);
            notify("User joined", `${msg.username} joined the session`);
        }
    }

    const handleWelcomeUserMessage = (msg: WelcomeUserMessage) => {
        //TODO this is kind of annoying. maps get parsed an object by default even when
        // explicitly trying to case. Maybe we can do something like what i did below in some generic middleware.
        setupPokerStore({
            id: roomId,
            name: msg.name,
            users: new Map(Object.entries(msg.users)),
            round: new Map(Object.entries(msg.currentRound)),
            votingSystem: msg.votingSystem
        })
    }

    const handleInstructionMessage = (msg: InstructionMessage) => {
        if (msg.instruction === "CLEAR_VOTES") {
            setLastRoundResults(null);
            setSelectedCard(null);
            clearVotes();
            notify("Votes cleared", `${users.get(msg.username)} cleared the votes`);
            return;
        }
    }

    const handlePokerContextMessage = (msg: PokerContextMessage) => {
        setSelectedCard(msg.currentVote);
    }

    const handleVotingFinalisedMessage = (msg: VotingFinalisedMessage) => {
        notify("Voting finalised", "This round is complete");
        setLastRoundResults(msg);
    }

    useEffect(() => {

        while (messageQueue.length > 0) {

            const msg = messageQueue.shift();
            
            switch(msg.type) {
                case "UserVotedMessage":        handleUserVotedMessage(msg.data as UserVotedMessage); break;
                case "InstructionMessage":      handleInstructionMessage(msg.data as InstructionMessage); break;
                case "UserStateChangeMessage":  handleUserStateChangeMessage(msg.data as UserStateChangeMessage); break;
                case "UserUpdateMessage":       handleUserUpdateMessage(msg.data as UserUpdateMessage); break;
                case "WelcomeUserMessage":      handleWelcomeUserMessage(msg.data as WelcomeUserMessage); break;
                case "PokerContextMessage":     handlePokerContextMessage(msg.data as PokerContextMessage); break;
                case "VotingFinalisedMessage":  handleVotingFinalisedMessage(msg.data as VotingFinalisedMessage); break;
                default:                        console.log(`No handler for message of type ${msg.type}, ignoring`); break;
            }
        }
    }, [messageQueue])

    const getButtons = () => {

        if (lastRoundResults === null) {
            return (
                <>
                    <Button flex="1" onClick={onClearVotes}>
                        Clear votes
                    </Button>
                    <Button flex="1" onClick={onTallyVotes}>
                        Tally votes
                    </Button>
                </>
        )}


        return (
            <>
                <Button flex="1" onClick={onClearVotes}>
                    Start next round
                </Button>
            </>
        )
    }

    return (
        <Flex flexDir="column" rowGap={4} justify={"space-evenly"} minH={"100vh"}>
            <Flex flex={"1 1 0"} flexDir="column" justify={"center"}>
                {lastRoundResults !== null && <ResultsTable {...lastRoundResults}></ResultsTable>}
            </Flex>
            <Flex flex={"1 1 0"} flexDir={"column"} rowGap={4}> 
                <Flex flexWrap="wrap" justify="center" columnGap={4} rowGap={2}>
                    {cardValues.map(c => <PokerCard key={c} value={c} onClick={onSelectCard}/>)}
                </Flex>
                <Flex columnGap={2}>
                    {getButtons()}
                </Flex>
            </Flex>
        </Flex>
    )
}