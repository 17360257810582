import {Container, Flex} from "@chakra-ui/react";
import {PokerView} from "components/PokerView";
import {NewGame} from "./NewGame";
import {NewPlayer} from "./NewPlayer";
import React from "react";
import {usePokerRoomStore} from "../http/PokerRoomStore";
import {useSocketStore} from "../http/SocketStore";
import {RightBar} from "./RightBar";
import { ErrorView } from "./ErrorView";
import { useHttpStore } from "http/HttpStore";

export const MainView: React.FC = () => {

    const roomId = usePokerRoomStore((state) => state.id);
    const [conn, socketError, sessionInfo] = useSocketStore(state => [state._socket, state.error, state.sessionInfo])
    const httpError = useHttpStore((state) => state.error)

    const hasError = () => {
        return socketError != null || httpError != null;
    }

    const renderView = () => {
        if (hasError()) {
            return <ErrorView/>;
        }
        if (roomId == null) {
            return <NewGame/>;
        }
        if (conn == null) {
            return <NewPlayer/>;
        }

        return <PokerView/>;
    }

    const renderBar = () => {
        return !hasError() && conn != null && <RightBar {...sessionInfo}/>;
    }

    return (
        <Flex>
            <Container
                maxW="container.xl"
                minH="100vh"
                display="flex"
                flexDir={"column"}
                justifyContent="center"
                p={0}
                centerContent
            >
                <Flex>{renderView()}</Flex>
            </Container>
            {renderBar()}
        </Flex>
    )
};