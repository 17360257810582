/**
 * A slightly nicer interface to {@linkcode Window.localStorage} or
 * {@linkcode Window.sessionStorage}.
 */
export interface StorageManager<T> {

    get: (key: string) => T|null
    set: (key: string, value: T) => void
    del: (key: string) => void
}

/**
 * @param storage the storage to manage, oneof sessionStorage, localStorage
 * @param pred type predicate to use when retrieving elements
 * @returns a new {@linkcode StorageManager}
 */
export const storageManagerFor = <T>(storage: Storage, pred: (obj: unknown) => obj is T) => {

    const get = (key: string) => {
        let val = storage.getItem(key);
        if (val === null) {
            return null;
        }
        
        let ret = JSON.parse(val);
        if (pred(ret)) {
            return ret;
        }

        return null;
    }

    const set = (key: string, value: T) => {
        storage.setItem(key, JSON.stringify(value));
    }

    const del = (key: string) => {
        storage.removeItem(key);
    }

    return {
        get: get,
        set: set, 
        del: del
    }
}