import { Box, Code, Heading, VStack } from "@chakra-ui/react"
import { useHttpStore } from "http/HttpStore";
import { useSocketStore } from "http/SocketStore"
import { PpokerError } from "types/Util";

export const ErrorView = () => {

    const socketError = useSocketStore((state) => state.error);
    const httpError = useHttpStore((state) => state.error);

    const getBody = (error: PpokerError) => {

        const getText = () => {
            switch(error.type) {
                case "TIMEOUT":
                    return [
                        "Connection timed out", 
                        "Your connection to the server was timed out due to inactivity",
                        null
                    ];
                case "SERVER_ERROR":
                    return [
                        "Server error", 
                        `
                        The server encountered an abnormal condition and closed your connection. 
                        It gave the following reason:
                        `,
                        error.reason
                    ];
                case "UNKNOWN_ERROR":
                    return [
                        "Unknown error",
                        `
                        An unknown error caused your connection to be closed. The server
                        gave the following reason: 
                        `,
                        error.reason
                    ];
                case "NOT_FOUND":
                    return [
                        "404",
                        "That means not found. Whatever you were looking for isn't here",
                        null
                    ]
            }
        }


        const [heading, body, reason] = getText();
        return (
            <>
                <Heading>{heading}</Heading>
                <Box>{body}{reason ? <Code>{reason}</Code> : <></>}.</Box>
            </>
        )
    }

    return (
        <VStack>
            {httpError != null ? getBody(httpError) : getBody(socketError)}
        </VStack>
    );
}