export type SessionInfo = {
    roomId: string
    userId: string
}

export const isSessionInfo = (obj: unknown): obj is SessionInfo => {

    return typeof obj === "object" 
        && "roomId" in obj && typeof obj.roomId === "string"
        && "userId" in obj && typeof obj.userId === "string"
}

export const SessionInfoPlaceholder: SessionInfo = {
    roomId: "", userId: ""
}

/** Abstract data carrier. `type` should be the name of the type `T`. */
export type Message<T> = {
    type: string,
    data: T
}

export type UserVotedMessage = {
    userId: string,
    vote: string,
    shouldAnonymise: boolean
}

export const newUserVotedMessage = (userId: string, vote: string): Message<UserVotedMessage> => {
    return {
        type: "UserVotedMessage",
        data: {
            userId: userId,
            vote: vote,
            shouldAnonymise: true
        }
    }
}

export type PokerContextMessage = {
    currentVote: string
}


export type InstructionMessage = {
    username: string,
    instruction: InstructionType
}

type InstructionType = "CLEAR_VOTES" | "TALLY_VOTES";

export const newInstructionMessage = (username: string, instruction: InstructionType): Message<InstructionMessage> => {
    return {
        type: "InstructionMessage",
        data: {
            username: username,
            instruction: instruction
        }
    }
}

export type VotingFinalisedMessage = {
    userId: string,
    average: number,
    votes: Record<string, string>
}


export type UserStateChangeMessage = {
    userId: string,
    state: UserStateChangeType
}

export type UserUpdateMessage = {
    userId: string,
    username: string,
    state: UserUpdateType
}

export type WelcomeUserMessage = {
    name: string,
    users: Map<string, string>,
    currentRound: Map<string, number>,
    votingSystem: string
}

type UserStateChangeType = "DISCONNECTED"
type UserUpdateType = "JOINED" | "CHANGED_NAME"
