import React from "react";
import {useForm} from 'react-hook-form'
import {Button, Container, FormControl, FormLabel, Input, Select} from "@chakra-ui/react";
import {usePokerRoomStore} from "../http/PokerRoomStore";
import { post } from "http/Http";
import { useHttpStore } from "http/HttpStore";
export const NewGame = () => {

    const setRoomId = usePokerRoomStore((state) => state.setId);
    const setHttpError = useHttpStore((state) => state.setError);

    const {
        handleSubmit,
        register,
        formState: { isSubmitting },
    } = useForm()
    async function onSubmit(values: { votingSystem: string, gameName: string }) {
        const response = await post(values.gameName, { votingSystem: values.votingSystem });
        if (!response.ok) {
            setHttpError(response);
            return;
        }

        const roomId = response.body as string;
        window.history.pushState(null, null, roomId);
        setRoomId(roomId);
    }

    return (
        <Container>
            <form onSubmit={handleSubmit(onSubmit)}>
                <FormControl>
                    <FormLabel>Game name</FormLabel>
                    <Input {...register("gameName")}/>
                </FormControl>
                <FormControl>
                    <FormLabel>Voting system</FormLabel>
                    <Select {...register("votingSystem")} defaultValue='Fibonacci (0, 1, 2, 3, 5, 8, 13, 21, 34, 55, 89)'>
                        <option>Fibonacci (0, 1, 2, 3, 5, 8, 13, 21, 34, 55, 89)</option>
                        <option>T-shirts (xxs, xs, s, m, l, xl, xxl)</option>
                    </Select>
                </FormControl>
                <Button
                    mt={4}
                    colorScheme='teal'
                    width={"100%"}
                    isLoading={isSubmitting}
                    type='submit'>
                    Create game
                </Button>
            </form>
        </Container>)
}